import { ClientCreateValue } from "../../types";

export const initialValues: ClientCreateValue = {
  name: "",
  surname: "",
  fathersName: "",
  sex: "",
  birthdate: "",
  snils: "",
  phoneNumber: "",
  email: "",
};
