import { useOrganizationsDataGrid } from "../../hooks/useOrganizationsDataGrid/useOrganizationsDataGrid";
import { useOrganizationsHeaderButtons } from "../../hooks/useOrganizationsHeaderButtons";
import { useOrganizationsColumns } from "../../hooks/useOrganizationsColumns";
import { Outlet, useNavigate } from "react-router";
import React, { useMemo } from "react";
import { DataGrid } from "@genome-frontend/uikit/build/lib/uikit";
import { GridEventListener } from "@mui/x-data-grid";
import { GridEvents } from "@genome-frontend/uikit/build/lib/uikit/DataGrid";
import { paths } from "../../../../router/paths";

export function OrganizationsGrid() {
  const gridData = useOrganizationsDataGrid();
  const headerButtons = useOrganizationsHeaderButtons(gridData);
  const columns = useOrganizationsColumns(gridData);

  const navigate = useNavigate();

  const columnsMemo = useMemo(() => columns, [columns]);

  const headerButtonsMemo = useMemo(() => headerButtons, [headerButtons]);

  const { gridProps } = gridData;

  const handleRowDoubleClick: GridEventListener<GridEvents.rowClick> = ({
    row,
  }) => {
    navigate(paths.organization(row.id));
  };

  return (
    <>
      <DataGrid
        {...gridProps}
        headerButtons={headerButtonsMemo}
        onRowDoubleClick={handleRowDoubleClick}
        columns={columnsMemo}
        rowsPerPageOptions={[10, 30, 50]}
        showOrderColumn
        showColumnSettings={false}
        checkboxSelection={false}
        gridKey="organizations"
      />
      <Outlet />
    </>
  );
}
