import React, { FC, memo } from "react";
import {
  Select as MuiSelect,
  SelectProps as MuiSelectProps,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import { Label } from "@genome-frontend/uikit/build/lib/uikit/Label/Label";
import { TSelectOption } from "@genome-frontend/uikit/build/lib/uikit/Select";
import style from "./style.module.scss";

export interface SelectValidationProps extends MuiSelectProps {
  options?: TSelectOption[];
  helperText?: any;
}

export const SelectValidation: FC<SelectValidationProps> = memo(
  ({
    label,
    required,
    options,
    size = "small",
    error,
    helperText,
    children,
    ...props
  }) => {
    return (
      <div className={style.select}>
        {label && <Label required={required}>{label}</Label>}
        <MuiSelect size={size} {...props} sx={{ width: "100%" }}>
          {options &&
            options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          {children}
        </MuiSelect>
        <FormHelperText error={error}>{helperText}</FormHelperText>
      </div>
    );
  }
);
