import { OrganizationViewFragment } from "generated/operations";
import React, { FC } from "react";
import { Grid, Label } from "@genome-frontend/uikit/build/lib/uikit";
import { Okved, OrganizationValues } from "../../types";
import { FieldArray, useFormikContext } from "formik";
import { locale } from "locale";
import { ValidationTextInputField } from "components/formFields/ValidationTextInputField";
import { ValidationDatePickerField } from "components/formFields/ValidationDatePickerField";
import { TValidationFieldContext } from "components/formFields/base/ValidationFieldsContext";
import { ValidationFileField } from "components/formFields/ValidationFileField";
import { MaskValidationTextInput } from "components/formFields/MaskValidationTextInput";
import { MaskExpress } from "utils/maskExpression";
import { SelectOkved } from "./SelectOkved";
import { OrganizationEditFormValidate } from "./OrganizationEditFormValidate";
import { Button } from "@mui/material";
import {
  OrganizationLicenseAcceptableExt,
  OrganizationLicenseAcceptableMimeTypes,
  OrganizationStampAcceptableExt,
  OrganizationStampAcceptableMimeTypes,
} from "constants/fileExtension";
import { useToasts } from "components/Toast";

const emptyOkved: Okved = {
  id: undefined,
  name: "",
};

const maxAdditionalOkvedsNumber = 10;

interface OrganizationEditFormProps {
  organization?: OrganizationViewFragment;
  isSubmitting?: boolean;
}

export const OrganizationEditForm: FC<OrganizationEditFormProps> = ({
  organization,
  isSubmitting,
}) => {
  const {
    setFieldValue,
    touched,
    errors,
    setFieldError,
    setFieldTouched,
    values,
  } = useFormikContext<OrganizationValues>();

  const { addErrorsToast } = useToasts();

  let validationFieldContext: TValidationFieldContext = {
    setFieldValue: setFieldValue,
    formValidationFunc: OrganizationEditFormValidate as any,
    setFieldError: setFieldError,
    setFieldTouched: setFieldTouched,
    touched: touched,
    errors: errors,
  };

  return (
    <>
      <Grid alignItems="flex-start" spacing={2} container>
        <Grid xs={12} item>
          <ValidationTextInputField
            validationFieldContext={validationFieldContext}
            name="name"
            label={locale.organization.name}
            fullWidth
            required
          />
        </Grid>

        <Grid xs={6} item>
          <ValidationTextInputField
            validationFieldContext={validationFieldContext}
            name="republicCode"
            label={locale.organization.republicCode}
            required
            type="number"
          />
        </Grid>

        <Grid xs={6} item>
          <ValidationTextInputField
            validationFieldContext={validationFieldContext}
            name="organizationCode"
            label={locale.organization.organizationCode}
            required
            type="number"
          />
        </Grid>

        <Grid xs={12} item>
          <ValidationTextInputField
            validationFieldContext={validationFieldContext}
            name="address"
            label={locale.organization.address}
            fullWidth
            required
          />
        </Grid>

        <Grid xs={12} item>
          <ValidationTextInputField
            validationFieldContext={validationFieldContext}
            name="inn"
            label={locale.organization.inn}
            required
            type="number"
          />
        </Grid>

        <Grid xs={12} item>
          <ValidationTextInputField
            validationFieldContext={validationFieldContext}
            name="ogrn"
            label={locale.organization.ogrn}
            required
            type="number"
          />
        </Grid>

        <Grid xs={12} item>
          <ValidationTextInputField
            validationFieldContext={validationFieldContext}
            name="kpp"
            label={locale.organization.kpp}
            required
            type="number"
          />
        </Grid>

        <Grid xs={12} item>
          <ValidationTextInputField
            validationFieldContext={validationFieldContext}
            name="licenseNumber"
            label={locale.organization.licenseNumber}
            required
          />
        </Grid>

        <Grid xs={12} item>
          <ValidationFileField
            acceptableMimeTypes={OrganizationLicenseAcceptableMimeTypes}
            acceptableExtensions={OrganizationLicenseAcceptableExt}
            label={locale.organization.licenseFile}
            name="license"
            fileFieldName="licenseFile"
            textFieldName="licenseFileText"
            validationFieldContext={validationFieldContext}
            uploadButtonText="Загрузить файл лицензии"
            required
            maxSize={5}
          />
        </Grid>

        <Grid xs={4} item>
          <ValidationDatePickerField
            validationFieldContext={validationFieldContext}
            name="licenseDate"
            label={locale.organization.licenseDate}
            required
          />
        </Grid>

        <Grid xs={12} item>
          <ValidationTextInputField
            validationFieldContext={validationFieldContext}
            name="licenseAuthority"
            label={locale.organization.licenseAuthority}
            fullWidth
            required
          />
        </Grid>

        <Grid xs={12} item>
          <ValidationFileField
            acceptableMimeTypes={OrganizationStampAcceptableMimeTypes}
            acceptableExtensions={OrganizationStampAcceptableExt}
            label={locale.organization.printFile}
            name="print"
            fileFieldName="printFile"
            textFieldName="printFileText"
            validationFieldContext={validationFieldContext}
            uploadButtonText="Загрузить файл печати"
            required
            maxSize={5}
          />
        </Grid>

        <Grid xs={12} item>
          <MaskValidationTextInput
            mask={MaskExpress.phone}
            required
            label={locale.organization.phone}
            name="phone"
            sx={{
              "& .MuiOutlinedInput-root": {
                width: "223px",
              },
            }}
            {...validationFieldContext}
          />
        </Grid>

        <Grid xs={12} item>
          <ValidationTextInputField
            validationFieldContext={validationFieldContext}
            name="email"
            label={locale.organization.email}
            required
            sx={{
              "& .MuiOutlinedInput-root": {
                width: "223px",
              },
            }}
          />
        </Grid>

        <Grid xs={12} item>
          <Label required>{locale.organization.mainOkved + ":"}</Label>
          <SelectOkved
            validationFieldContext={validationFieldContext}
            name="mainOkved"
          />
        </Grid>

        <Grid xs={12} item>
          <Label>{locale.organization.additionalOkveds + ":"}</Label>
        </Grid>
        <FieldArray name="additionalOkveds" validateOnChange>
          {
            // @ts-ignore
            ({ push, remove }) => {
              const onAddOkvedButtonClick = () => {
                if (
                  values.additionalOkveds!.length >= maxAdditionalOkvedsNumber
                ) {
                  addErrorsToast(
                    [
                      {
                        message: `Указано максимальное количество дополнительных ОКВЭД'ов: ${maxAdditionalOkvedsNumber}`,
                      },
                    ],
                    ""
                  );
                  return;
                }

                push(emptyOkved);
              };

              return (
                <React.Fragment>
                  {values.additionalOkveds!.map((okv, index) => (
                    <Grid container item key={index} spacing={2}>
                      <Grid item xs={10}>
                        <SelectOkved
                          validationFieldContext={validationFieldContext}
                          name={`additionalOkveds[${index}]`}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <Button fullWidth onClick={() => remove(index)}>
                          Удалить
                        </Button>
                      </Grid>
                    </Grid>
                  ))}

                  <Grid item>
                    <Button
                      disabled={isSubmitting}
                      variant="contained"
                      onClick={onAddOkvedButtonClick}
                    >
                      Добавить ОКВЭД
                    </Button>
                  </Grid>
                </React.Fragment>
              );
            }
          }
        </FieldArray>
      </Grid>
    </>
  );
};
