type Id = string | number;

export const paths = {
  login: () => "/login",
  homePage: () => "/",
  profile: () => "/profile",

  users: () => "/users",
  userCreate: () => `/users/create`,
  userCreateSubPath: () => "create",
  user: (id: Id = ":id") => `/users/${id}`,
  userSubPath: (id: Id = ":id") => `${id}`,
  userEdit: (id: Id = ":id") => `/users/${id}/edit`,
  userEditSubPath: () => `edit`,

  clients: () => "/clients",
  clientCreate: () => `/clients/create`,
  clientCreateSubPath: () => "create",
  client: (id: Id = ":id") => `/clients/${id}`,
  clientSubPath: (id: Id = ":id") => `${id}`,
  clientEdit: (id: Id = ":id") => `/clients/${id}/edit`,
  clientEditSubPath: () => `edit`,

  clientAppealView: (
    id: Id = ":id",
    appealId: Id = ":appealId",
    appealName: string = ":appealName"
  ) => `/clients/${id}/appealView/${appealId}/appealName/${appealName}`,

  reports: () => "/reports",

  organizations: () => "/organizations",
  organization: (id: Id = ":id") => `/organizations/${id}`,
  organizationNewSubPath: () => `new`,
  organizationNew: () => `/organizations/new`,
  organizationEdit: (id: Id = ":id") => `/organizations/${id}/edit`,

  changes: (id: Id = ":id") => `/changes/${id}`,
};
