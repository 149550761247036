export enum AccessPermissions {
  ClientsNavigation = "Administration.Clients.Navigation",
  ClientsRead = "Administration.Clients.Read",
  ClientsCreate = "Administration.Clients.Create",
  ClientsUpdate = "Administration.Clients.Update",
  ClientsDelete = "Administration.Clients.Delete",
  HistoryChangeNavigation = "Administration.HistoryChange.Navigation",
  HistoryChangeRead = "Administration.HistoryChange.Read",
  EmployeesNavigation = "Administration.Employees.Navigation",
  EmployeesRead = "Administration.Employees.Read",
  EmployeesCreate = "Administration.Employees.Create",
  EmployeesUpdate = "Administration.Employees.Update",
  EmployeesDelete = "Administration.Employees.Delete",
  OrganizationNavigation = "Administration.Organizations.Navigation",
  OrganizationsRead = "Administration.Organizations.Read",
  OrganizationsCreate = "Administration.Organizations.Create",
  OrganizationsUpdate = "Administration.Organizations.Update",
  OrganizationsDelete = "Administration.Organizations.Delete",
  Permissions = "Administration.Permissions",
  Reports = "Administration.Reports",
}
