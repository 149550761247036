import React, { FC } from "react";
import { useClientQuery } from "generated/hooks";
import { ClientViewFragment } from "generated/operations";
import {
  Drawer,
  Typography,
  Grid,
  Label,
  DrawerContent,
  Box,
  Button,
} from "@genome-frontend/uikit/build/lib/uikit";

import { locale } from "locale";
import { getFio } from "utils/getFio";
import { FormatDate } from "utils/formatDate";
import { CenteredCircularProgress } from "components/CenteredCircularProgress";
import { getAddressDate } from "utils/getAddressDate";
import { PencilOutlineIcon } from "@genome-frontend/uikit/build/lib/icons/PencilOutlineIcon";
import { useAuth } from "auth/useAuth";
import { AppealsGrid } from "pages/AppealPage/components/AppealsGrid/AppealsGrid";
import { AccessPermissions } from "types/AccessPermissions";
import { useParams } from "react-router";
import { AppealView } from "pages/AppealPage/components/AppealView";
import { Breadcrumbs, Link } from "@mui/material";
import { paths } from "router/paths";
import { TokenHelper } from "TokenHelper";
import { UserRole } from "types/UserRole";
import "./ClientViewDrawer.css";
import { getSexName } from "utils/getSexName";

interface ClientViewDrawerProps {
  id: String;
  onClose: () => void;
  onError: () => void;
  onEdit: (user: ClientViewFragment) => void;
  onRemoveSuccess: () => void;
}

export const ClientViewDrawer: FC<ClientViewDrawerProps> = ({
  id,
  onClose,
  onError,
  onEdit,
}) => {
  const { data, loading } = useClientQuery({
    variables: {
      id,
    },
    onError: () => {
      onError();
    },
  });

  const { hasAccessPermission } = useAuth();
  const params = useParams();
  const appealId: number = Number(params.appealId);
  const appealName: string = String(params.appealName);
  const isAppealView: boolean = params.appealId !== undefined;

  const roleName = TokenHelper.getCurrentUserRole();
  const isViewAppeals: boolean =
    roleName === UserRole.Doctor ||
    roleName === UserRole.Therapist ||
    roleName === UserRole.LawOfficial;

  const closeHandler = () => {
    onClose();
  };

  const editHandler = () => {
    if (data?.client) {
      onEdit(data.client);
    }
  };

  if (!loading && !data) {
    return null;
  }

  return (
    <Drawer
      sx={{
        "& .MuiPaper-root": {
          width: 920,
        },
      }}
      open
      onClose={closeHandler}
    >
      <DrawerContent
        onClose={closeHandler}
        title={data?.client ? getFio(data.client, true) : ""}
        isWithActions={false}
      >
        {hasAccessPermission(AccessPermissions.ClientsUpdate) && (
          <Box className={"box_header"}>
            <Box className={"box_center"}></Box>
            <Box className={"box_center"}>
              <Button
                sx={{ ml: 1 }}
                startIcon={<PencilOutlineIcon />}
                onClick={editHandler}
              >
                {locale.EDIT}
              </Button>
            </Box>
          </Box>
        )}
        {loading ? (
          <CenteredCircularProgress />
        ) : (
          <>
            <Grid spacing={2} container>
              {data?.client && (
                <>
                  <Grid xs={12} item>
                    <Breadcrumbs aria-label="breadcrumb">
                      <Link underline="hover" href={paths.clients()}>
                        К списку пользователей
                      </Link>
                      {!isAppealView && isViewAppeals && (
                        <Label>Заявки пользователя</Label>
                      )}
                      {isAppealView && (
                        <Link
                          underline="hover"
                          href={paths.client(id.toString())}
                        >
                          Заявки пользователя
                        </Link>
                      )}
                      {appealName && isAppealView && (
                        <Label>{appealName}</Label>
                      )}
                    </Breadcrumbs>
                  </Grid>

                  <Grid xs={4} item>
                    <Label>{locale.user.surname}</Label>
                    <Typography>{data?.client.surname}</Typography>
                  </Grid>
                  <Grid xs={4} item>
                    <Label>{locale.user.name}</Label>
                    <Typography>{data?.client.name}</Typography>
                  </Grid>
                  <Grid xs={4} item>
                    <Label>{locale.user.fathersName}</Label>
                    <Typography>{data?.client.fathersName}</Typography>
                  </Grid>
                  <Grid xs={4} item>
                    <Label>{locale.user.sex}</Label>
                    <Typography>{getSexName(data?.client.sex)}</Typography>
                  </Grid>
                  <Grid xs={4} item>
                    <Label>{locale.user.snils}</Label>
                    <Typography>{data?.client.snils}</Typography>
                  </Grid>
                  <Grid xs={4} item>
                    <Label>{locale.user.birthdate}</Label>
                    <FormatDate date={data?.client?.birthdate} />
                  </Grid>
                  <Grid xs={4} item>
                    <Label>{locale.user.phoneNumber}</Label>
                    <Typography>{data?.client.phoneNumber}</Typography>
                  </Grid>
                  <Grid xs={4} item>
                    <Label>{locale.user.email}</Label>
                    <Typography>{data?.client.email}</Typography>
                  </Grid>

                  <Grid container item>
                    <Grid xs={7} item>
                      <Label>
                        <h3>Регистрация пользователя за последние 5 лет</h3>
                      </Label>
                    </Grid>
                  </Grid>

                  {data?.client?.addresses?.map((adr, index) => {
                    return (
                      <Grid container item key={index}>
                        <Grid xs={7} item>
                          <Typography>{adr.fullAddress}</Typography>
                        </Grid>
                        <Grid xs={5} item>
                          <Typography>{getAddressDate(adr)}</Typography>
                        </Grid>
                      </Grid>
                    );
                  })}
                </>
              )}
            </Grid>
            {!isAppealView && isViewAppeals && (
              <Box className={"container"}>
                <AppealsGrid id={id.toString()} />
              </Box>
            )}
            {isAppealView && isViewAppeals && (
              <Box className={"container"}>
                <AppealView clientId={id.toString()} appealId={appealId} />
              </Box>
            )}
          </>
        )}
      </DrawerContent>
    </Drawer>
  );
};
