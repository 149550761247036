import { FC } from "react";
import { useToasts } from "components/Toast";
import { Drawer, DrawerContent } from "@genome-frontend/uikit/build/lib/uikit";
import { useCreateOrganizationMutation } from "generated/hooks";
import { OrganizationValues } from "../../types";
import { namedOperations } from "generated/operations";
import { Formik } from "formik";
import { DrawerForm } from "components/Form";
import { OrganizationCreateForm } from "../OrganizationForm/OrganizationCreateForm";
import { OrganizationCreateFormValidate } from "../OrganizationForm/OrganizationCreateFormValidate";
import { getInitialValues } from "../../utils/getInitialValues";
import { getCreateInputDataFromValues } from "../../utils/getCreateInputDataFromValues";
import { isAllFormValuesWasTouched } from "utils/isAllFormValuesWasTouched";
import { useConfirm } from "components/Confirm";
import { formCloseHandler } from "components/Form/formCloseHandler";

interface OrganizationCreateDrawerProps {
  onSuccess: (data?: number | undefined) => void;
  onCancel: () => void;
}

const initialValues: OrganizationValues = getInitialValues();

export const OrganizationCreateDrawer: FC<OrganizationCreateDrawerProps> = ({
  onSuccess,
  onCancel,
}) => {
  const { addSuccessToast, addErrorsToast } = useToasts();
  const { addOkConfirm } = useConfirm();

  const [createOrganizationMutation, { loading: saving }] =
    useCreateOrganizationMutation();

  const createOrganization = async (values: OrganizationValues) => {
    const organizationInput = getCreateInputDataFromValues(values);
    const { data } = await createOrganizationMutation({
      variables: {
        input: organizationInput,
      },
      refetchQueries: [namedOperations.Query.Organizations],
    });

    if (
      data?.createOrganization &&
      data?.createOrganization?.iCreateOrganizationResult
    ) {
      let createOrganizationResult =
        data.createOrganization.iCreateOrganizationResult;

      if ("errors" in createOrganizationResult) {
        addErrorsToast(createOrganizationResult.errors);
        return;
      }

      if ("data" in createOrganizationResult) {
        addSuccessToast("Организация добавлена");
        onSuccess(createOrganizationResult.data);
      }
    }
  };

  const submitHandler = (values: OrganizationValues) => {
    createOrganization(values);
  };

  const closeHandler = async (values: OrganizationValues) =>
    formCloseHandler({ values, initialValues, onCancel, addOkConfirm });

  let values: OrganizationValues;

  return (
    <Drawer
      sx={{
        "& .MuiPaper-root": {
          width: 1000,
        },
      }}
      open
      onClose={() => closeHandler(values)}
    >
      <Formik<OrganizationValues>
        onSubmit={submitHandler}
        initialValues={initialValues}
        validate={OrganizationCreateFormValidate}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {(props) => {
          values = props.values;
          return (
            <DrawerForm>
              <DrawerContent
                onClose={() => closeHandler(props.values)}
                title="Новая организация"
                confirmButtonProps={{
                  disabled: !(
                    props.isValid &&
                    props.dirty &&
                    isAllFormValuesWasTouched(
                      props,
                      getCreateInputDataFromValues
                    )
                  ),
                }}
                cancelButtonProps={{
                  onClick: () => closeHandler(props.values),
                }}
              >
                <OrganizationCreateForm />
              </DrawerContent>
            </DrawerForm>
          );
        }}
      </Formik>
    </Drawer>
  );
};
