import { FormikProps } from "formik";

export const isAllFormValuesWasTouched = <T>(
  props: FormikProps<T>,
  getInputValues: Function
): boolean => {
  return (
    Object.keys(props.touched).length ===
    Object.keys(getInputValues(props.values)).length
  );
};
