import { MainLayout } from "../../components/MainLayout";
import { OrganizationsGrid } from "./Components/OrganizationsGrid/OrganizationsGrid";

export function OrganizationsPage() {
  return (
    <MainLayout title="Организации">
      <OrganizationsGrid />
    </MainLayout>
  );
}
