// THIS FILE IS AUTOGENERATED. PLEASE DO NOT EDIT IT
import * as Types from "./operations";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export const AppealViewFragmentDoc = gql`
  fragment AppealView on Appeal {
    id
    name
    status
    isActual
    responseDate
    patientId
  }
`;
export const AppealTypeItemFragmentDoc = gql`
  fragment AppealTypeItem on AppealType {
    id
    name
  }
`;
export const AppealByAdminViewFragmentDoc = gql`
  fragment AppealByAdminView on AppealEntity {
    id
    name
    isActual
    documents {
      id
      name
      number
      responseDate
      isActual
      conclusionFile {
        fileName
        file
      }
    }
  }
`;
export const ConclusionAppealViewFragmentDoc = gql`
  fragment ConclusionAppealView on ConclusionAppeal {
    id
    parentId
    patientId
    name
    number
    type
    conclusionType
    conclusionFile {
      fileName
      file
    }
    restrictionType
    restrictionDateEnd
    responseDate
    isActual
  }
`;
export const CreateAppealsPayloadFragmentDoc = gql`
  fragment CreateAppealsPayload on CreateAppealsPayload {
    appealEntity {
      id
      name
      status
      isActual
      responseDate
    }
    errors {
      ... on EntityCanNotCreatedError {
        message
      }
      ... on EntityNotFoundError {
        message
      }
    }
  }
`;
export const CreateConclusionPayloadFragmentDoc = gql`
  fragment CreateConclusionPayload on CreateConclusionPayload {
    conclusionAppeal {
      id
      parentId
      name
      number
      type
      conclusionType
      conclusionFile {
        fileName
        file
      }
      restrictionType
      restrictionDateEnd
      responseDate
      isActual
    }
    errors {
      ... on EntityCanNotCreatedError {
        message
      }
      ... on EntityNotFoundError {
        message
      }
    }
  }
`;
export const CreateTherapistConclusionPayloadFragmentDoc = gql`
  fragment CreateTherapistConclusionPayload on CreateTherapistConclusionPayload {
    conclusionAppeal {
      id
      parentId
      name
      number
      type
      conclusionType
      conclusionFile {
        fileName
        file
      }
      restrictionType
      restrictionDateEnd
      responseDate
      isActual
    }
    errors {
      ... on EntityCanNotCreatedError {
        message
      }
      ... on EntityNotFoundError {
        message
      }
    }
  }
`;
export const DeleteConclusionPayloadFragmentDoc = gql`
  fragment DeleteConclusionPayload on DeleteConclusionPayload {
    errors {
      ... on EntityNotFoundError {
        message
      }
      ... on OperationFailedError {
        message
      }
    }
  }
`;
export const DeleteTherapistConclusionPayloadFragmentDoc = gql`
  fragment DeleteTherapistConclusionPayload on DeleteTherapistConclusionPayload {
    errors {
      ... on EntityNotFoundError {
        message
      }
      ... on OperationFailedError {
        message
      }
    }
  }
`;
export const DiagnosisViewFragmentDoc = gql`
  fragment DiagnosisView on Diagnosis {
    id
    name
  }
`;
export const LoadConclusionPayloadFragmentDoc = gql`
  fragment LoadConclusionPayload on LoadConclusionPayload {
    conclusionAppeal {
      id
      parentId
      name
      number
      type
      conclusionType
      conclusionFile {
        fileName
        file
      }
      restrictionType
      restrictionDateEnd
      responseDate
      isActual
    }
    errors {
      ... on EntityCanNotCreatedError {
        message
      }
      ... on EntityNotFoundError {
        message
      }
    }
  }
`;
export const LoadTherapistConclusionPayloadFragmentDoc = gql`
  fragment LoadTherapistConclusionPayload on LoadTherapistConclusionPayload {
    conclusionAppeal {
      id
      parentId
      name
      number
      type
      conclusionType
      conclusionFile {
        fileName
        file
      }
      restrictionType
      restrictionDateEnd
      responseDate
      isActual
    }
    errors {
      ... on EntityCanNotCreatedError {
        message
      }
      ... on EntityNotFoundError {
        message
      }
    }
  }
`;
export const ConclusionFileHistoryItemFragmentDoc = gql`
  fragment ConclusionFileHistoryItem on ConclusionFileHistory {
    id
    organizationName
    appealType
    clientFio
    doctorFio
    doctorPosition
    conclusionName
    conclusionNumber
    conclusionDate
    prevFileName
    newFileName
    dateChanged
    trackedTime
  }
`;
export const HistoryItemFragmentDoc = gql`
  fragment HistoryItem on History {
    id
    currentValue
    dateChanged
    trackedTime
    prevValue
    clientFio
    employeeFio
    organizationName
  }
`;
export const ClientViewFragmentDoc = gql`
  fragment ClientView on Client {
    id
    name
    surname
    fathersName
    sex
    birthdate
    snils
    phoneNumber
    email
    addresses {
      fullAddress
      startDate
      endDate
    }
  }
`;
export const ClientItemFragmentDoc = gql`
  fragment ClientItem on Client {
    id
    name
    surname
    fathersName
    fio
    sex
    birthdate
    snils
    phoneNumber
    email
    addresses {
      fullAddress
      startDate
      endDate
    }
  }
`;
export const CreateOrganizationFailureFragmentDoc = gql`
  fragment CreateOrganizationFailure on CreateOrganizationFailure {
    errors {
      code
      message
    }
  }
`;
export const CreateOrganizationSuccessFragmentDoc = gql`
  fragment CreateOrganizationSuccess on CreateOrganizationSuccess {
    data
    message
  }
`;
export const CreateOrganizationPayloadFragmentDoc = gql`
  fragment CreateOrganizationPayload on CreateOrganizationPayload {
    iCreateOrganizationResult {
      ...CreateOrganizationFailure
      ...CreateOrganizationSuccess
    }
  }
  ${CreateOrganizationFailureFragmentDoc}
  ${CreateOrganizationSuccessFragmentDoc}
`;
export const OkvedViewFragmentDoc = gql`
  fragment OkvedView on Okved {
    id
    code
    name
  }
`;
export const OrganizationViewFragmentDoc = gql`
  fragment OrganizationView on Organization {
    id
    name
    address
    email
    inn
    kpp
    licenseAuthority
    licenseDate
    licenseNumber
    ogrn
    phone
    republicCode
    organizationCode
    licenseFile {
      fileName
      file
    }
    printFile {
      file
      fileName
    }
    mainOkved {
      id
      code
      name
    }
    additionalOkveds {
      id
      code
      name
    }
  }
`;
export const OrganizationFragmentDoc = gql`
  fragment Organization on Organization {
    id
    name
    address
  }
`;
export const UpdateOrganizationPayloadFragmentDoc = gql`
  fragment UpdateOrganizationPayload on UpdateOrganizationPayload {
    organization {
      id
    }
  }
`;
export const CreateCspPayloadFragmentDoc = gql`
  fragment CreateCspPayload on CreateCspPayload {
    cspCertificate {
      userId
      expires
    }
    errors {
      ... on ArgumentError {
        message
      }
      ... on EntityNotFoundError {
        message
      }
      ... on ArgumentNullError {
        message
      }
    }
  }
`;
export const CspPayloadFragmentDoc = gql`
  fragment CspPayload on CspCertificate {
    userId
    expires
    thumbprint {
      value
    }
  }
`;
export const UserProfileViewFragmentDoc = gql`
  fragment UserProfileView on UserProfile {
    name
    surname
    fathersName
    sex
    birthdate
    snils
    phoneNumber
    email
    position
    roleName
    profileName
    organizationName
  }
`;
export const ReportViewFragmentDoc = gql`
  fragment ReportView on Report {
    id
    name
    doctorFio
    doctorPosition
    patientFio
    patientBirthdate
    patientSnils
    responseDate
    number
    isActual
    documents {
      id
      name
      doctorFio
      doctorPosition
      patientFio: patient {
        fio
      }
      patientBirthdate: patient {
        birthdate
      }
      patientSnils: patient {
        snils
      }
      responseDate
      number
      isActual
      conclusionFile {
        fileName
        file
      }
    }
  }
`;
export const CreateUserPayloadFragmentDoc = gql`
  fragment CreateUserPayload on CreateUserPayload {
    user {
      id
      name
      surname
      fathersName
      sex
      birthdate
      snils
      phoneNumber
      email
      roleName
    }
    errors {
      ... on EntityCanNotCreatedError {
        message
      }
    }
  }
`;
export const DeleteUserPayloadFragmentDoc = gql`
  fragment DeleteUserPayload on DeleteUserPayload {
    user {
      id
    }
    errors {
      ... on EntityNotFoundError {
        message
      }
      ... on OperationFailedError {
        message
      }
    }
  }
`;
export const ProfileViewFragmentDoc = gql`
  fragment ProfileView on Profile {
    id
    name
  }
`;
export const RoleViewFragmentDoc = gql`
  fragment RoleView on Role {
    id
    name
    profiles {
      id
      name
    }
  }
`;
export const UpdateUserPayloadFragmentDoc = gql`
  fragment UpdateUserPayload on UpdateUserPayload {
    user {
      id
      login
      password
      name
      surname
      fathersName
      sex
      birthdate
      snils
      phoneNumber
      email
      roleName
      profileIds
      organizationId
      hasLoginPassAccess
      isAccessBlocked
    }
    errors {
      ... on EntityNotFoundError {
        message
      }
      ... on OperationFailedError {
        message
      }
    }
  }
`;
export const UserViewFragmentDoc = gql`
  fragment UserView on User {
    id
    login
    password
    name
    surname
    fathersName
    sex
    birthdate
    snils
    phoneNumber
    email
    position
    roleName
    profileIds
    organizationId
    hasLoginPassAccess
    isAccessBlocked
    organizationName
    profileNames
    profiles {
      id
      name
    }
  }
`;
export const UserItemFragmentDoc = gql`
  fragment UserItem on User {
    id
    login
    password
    name
    surname
    fathersName
    sex
    fio
    birthdate
    snils
    phoneNumber
    email
    position
    roleName
    profileIds
    profileNames
    organizationId
    organizationName
    hasLoginPassAccess
    isAccessBlocked
  }
`;
export const AppealDocument = gql`
  query Appeal($id: Int!) {
    appeal(id: $id) {
      ...AppealView
    }
  }
  ${AppealViewFragmentDoc}
`;

/**
 * __useAppealQuery__
 *
 * To run a query within a React component, call `useAppealQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppealQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppealQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAppealQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.AppealQuery,
    Types.AppealQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.AppealQuery, Types.AppealQueryVariables>(
    AppealDocument,
    options
  );
}
export function useAppealLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.AppealQuery,
    Types.AppealQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.AppealQuery, Types.AppealQueryVariables>(
    AppealDocument,
    options
  );
}
export type AppealQueryHookResult = ReturnType<typeof useAppealQuery>;
export type AppealLazyQueryHookResult = ReturnType<typeof useAppealLazyQuery>;
export type AppealQueryResult = Apollo.QueryResult<
  Types.AppealQuery,
  Types.AppealQueryVariables
>;
export const AppealTypesDocument = gql`
  query AppealTypes {
    appealTypes {
      totalCount
      items {
        ...AppealTypeItem
      }
    }
  }
  ${AppealTypeItemFragmentDoc}
`;

/**
 * __useAppealTypesQuery__
 *
 * To run a query within a React component, call `useAppealTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppealTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppealTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAppealTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.AppealTypesQuery,
    Types.AppealTypesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.AppealTypesQuery,
    Types.AppealTypesQueryVariables
  >(AppealTypesDocument, options);
}
export function useAppealTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.AppealTypesQuery,
    Types.AppealTypesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.AppealTypesQuery,
    Types.AppealTypesQueryVariables
  >(AppealTypesDocument, options);
}
export type AppealTypesQueryHookResult = ReturnType<typeof useAppealTypesQuery>;
export type AppealTypesLazyQueryHookResult = ReturnType<
  typeof useAppealTypesLazyQuery
>;
export type AppealTypesQueryResult = Apollo.QueryResult<
  Types.AppealTypesQuery,
  Types.AppealTypesQueryVariables
>;
export const AppealsDocument = gql`
  query Appeals(
    $id: UUID!
    $skip: Int
    $take: Int
    $order: [AppealSortInput!]
    $where: AppealOutputFilterInput
  ) {
    appeals(id: $id, skip: $skip, take: $take, order: $order, where: $where) {
      totalCount
      items {
        ...AppealView
      }
    }
  }
  ${AppealViewFragmentDoc}
`;

/**
 * __useAppealsQuery__
 *
 * To run a query within a React component, call `useAppealsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppealsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppealsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      order: // value for 'order'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAppealsQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.AppealsQuery,
    Types.AppealsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.AppealsQuery, Types.AppealsQueryVariables>(
    AppealsDocument,
    options
  );
}
export function useAppealsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.AppealsQuery,
    Types.AppealsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.AppealsQuery, Types.AppealsQueryVariables>(
    AppealsDocument,
    options
  );
}
export type AppealsQueryHookResult = ReturnType<typeof useAppealsQuery>;
export type AppealsLazyQueryHookResult = ReturnType<typeof useAppealsLazyQuery>;
export type AppealsQueryResult = Apollo.QueryResult<
  Types.AppealsQuery,
  Types.AppealsQueryVariables
>;
export const AppealsByAdminDocument = gql`
  query AppealsByAdmin(
    $id: UUID!
    $skip: Int
    $take: Int
    $order: [AppealEntitySortInput!]
    $where: AppealEntityOutputFilterInput
  ) {
    appealsByAdmin(
      id: $id
      skip: $skip
      take: $take
      order: $order
      where: $where
    ) {
      totalCount
      items {
        ...AppealByAdminView
      }
    }
  }
  ${AppealByAdminViewFragmentDoc}
`;

/**
 * __useAppealsByAdminQuery__
 *
 * To run a query within a React component, call `useAppealsByAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppealsByAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppealsByAdminQuery({
 *   variables: {
 *      id: // value for 'id'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      order: // value for 'order'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAppealsByAdminQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.AppealsByAdminQuery,
    Types.AppealsByAdminQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.AppealsByAdminQuery,
    Types.AppealsByAdminQueryVariables
  >(AppealsByAdminDocument, options);
}
export function useAppealsByAdminLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.AppealsByAdminQuery,
    Types.AppealsByAdminQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.AppealsByAdminQuery,
    Types.AppealsByAdminQueryVariables
  >(AppealsByAdminDocument, options);
}
export type AppealsByAdminQueryHookResult = ReturnType<
  typeof useAppealsByAdminQuery
>;
export type AppealsByAdminLazyQueryHookResult = ReturnType<
  typeof useAppealsByAdminLazyQuery
>;
export type AppealsByAdminQueryResult = Apollo.QueryResult<
  Types.AppealsByAdminQuery,
  Types.AppealsByAdminQueryVariables
>;
export const ConclusionAppealDocument = gql`
  query ConclusionAppeal(
    $id: Int!
    $roleName: String!
    $skip: Int
    $take: Int
    $order: [ConclusionAppealSortInput!]
    $where: ConclusionAppealOutputFilterInput
  ) {
    conclusionAppeals(
      id: $id
      roleName: $roleName
      skip: $skip
      take: $take
      order: $order
      where: $where
    ) {
      totalCount
      items {
        ...ConclusionAppealView
      }
    }
  }
  ${ConclusionAppealViewFragmentDoc}
`;

/**
 * __useConclusionAppealQuery__
 *
 * To run a query within a React component, call `useConclusionAppealQuery` and pass it any options that fit your needs.
 * When your component renders, `useConclusionAppealQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConclusionAppealQuery({
 *   variables: {
 *      id: // value for 'id'
 *      roleName: // value for 'roleName'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      order: // value for 'order'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useConclusionAppealQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.ConclusionAppealQuery,
    Types.ConclusionAppealQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.ConclusionAppealQuery,
    Types.ConclusionAppealQueryVariables
  >(ConclusionAppealDocument, options);
}
export function useConclusionAppealLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.ConclusionAppealQuery,
    Types.ConclusionAppealQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.ConclusionAppealQuery,
    Types.ConclusionAppealQueryVariables
  >(ConclusionAppealDocument, options);
}
export type ConclusionAppealQueryHookResult = ReturnType<
  typeof useConclusionAppealQuery
>;
export type ConclusionAppealLazyQueryHookResult = ReturnType<
  typeof useConclusionAppealLazyQuery
>;
export type ConclusionAppealQueryResult = Apollo.QueryResult<
  Types.ConclusionAppealQuery,
  Types.ConclusionAppealQueryVariables
>;
export const CreateAppealDocument = gql`
  mutation CreateAppeal($input: CreateAppealsInput!) {
    createAppeals(input: $input) {
      ...CreateAppealsPayload
    }
  }
  ${CreateAppealsPayloadFragmentDoc}
`;
export type CreateAppealMutationFn = Apollo.MutationFunction<
  Types.CreateAppealMutation,
  Types.CreateAppealMutationVariables
>;

/**
 * __useCreateAppealMutation__
 *
 * To run a mutation, you first call `useCreateAppealMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAppealMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAppealMutation, { data, loading, error }] = useCreateAppealMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAppealMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CreateAppealMutation,
    Types.CreateAppealMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.CreateAppealMutation,
    Types.CreateAppealMutationVariables
  >(CreateAppealDocument, options);
}
export type CreateAppealMutationHookResult = ReturnType<
  typeof useCreateAppealMutation
>;
export type CreateAppealMutationResult =
  Apollo.MutationResult<Types.CreateAppealMutation>;
export type CreateAppealMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateAppealMutation,
  Types.CreateAppealMutationVariables
>;
export const CreateConclusionDocument = gql`
  mutation CreateConclusion($input: CreateConclusionInput!) {
    createConclusion(input: $input) {
      ...CreateConclusionPayload
    }
  }
  ${CreateConclusionPayloadFragmentDoc}
`;
export type CreateConclusionMutationFn = Apollo.MutationFunction<
  Types.CreateConclusionMutation,
  Types.CreateConclusionMutationVariables
>;

/**
 * __useCreateConclusionMutation__
 *
 * To run a mutation, you first call `useCreateConclusionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateConclusionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createConclusionMutation, { data, loading, error }] = useCreateConclusionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateConclusionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CreateConclusionMutation,
    Types.CreateConclusionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.CreateConclusionMutation,
    Types.CreateConclusionMutationVariables
  >(CreateConclusionDocument, options);
}
export type CreateConclusionMutationHookResult = ReturnType<
  typeof useCreateConclusionMutation
>;
export type CreateConclusionMutationResult =
  Apollo.MutationResult<Types.CreateConclusionMutation>;
export type CreateConclusionMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateConclusionMutation,
  Types.CreateConclusionMutationVariables
>;
export const CreateTherapistConclusionDocument = gql`
  mutation CreateTherapistConclusion($input: CreateTherapistConclusionInput!) {
    createTherapistConclusion(input: $input) {
      ...CreateTherapistConclusionPayload
    }
  }
  ${CreateTherapistConclusionPayloadFragmentDoc}
`;
export type CreateTherapistConclusionMutationFn = Apollo.MutationFunction<
  Types.CreateTherapistConclusionMutation,
  Types.CreateTherapistConclusionMutationVariables
>;

/**
 * __useCreateTherapistConclusionMutation__
 *
 * To run a mutation, you first call `useCreateTherapistConclusionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTherapistConclusionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTherapistConclusionMutation, { data, loading, error }] = useCreateTherapistConclusionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTherapistConclusionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CreateTherapistConclusionMutation,
    Types.CreateTherapistConclusionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.CreateTherapistConclusionMutation,
    Types.CreateTherapistConclusionMutationVariables
  >(CreateTherapistConclusionDocument, options);
}
export type CreateTherapistConclusionMutationHookResult = ReturnType<
  typeof useCreateTherapistConclusionMutation
>;
export type CreateTherapistConclusionMutationResult =
  Apollo.MutationResult<Types.CreateTherapistConclusionMutation>;
export type CreateTherapistConclusionMutationOptions =
  Apollo.BaseMutationOptions<
    Types.CreateTherapistConclusionMutation,
    Types.CreateTherapistConclusionMutationVariables
  >;
export const DeleteConclusionDocument = gql`
  mutation DeleteConclusion($id: DeleteConclusionInput!) {
    deleteConclusion(input: $id) {
      ...DeleteConclusionPayload
    }
  }
  ${DeleteConclusionPayloadFragmentDoc}
`;
export type DeleteConclusionMutationFn = Apollo.MutationFunction<
  Types.DeleteConclusionMutation,
  Types.DeleteConclusionMutationVariables
>;

/**
 * __useDeleteConclusionMutation__
 *
 * To run a mutation, you first call `useDeleteConclusionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteConclusionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteConclusionMutation, { data, loading, error }] = useDeleteConclusionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteConclusionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.DeleteConclusionMutation,
    Types.DeleteConclusionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.DeleteConclusionMutation,
    Types.DeleteConclusionMutationVariables
  >(DeleteConclusionDocument, options);
}
export type DeleteConclusionMutationHookResult = ReturnType<
  typeof useDeleteConclusionMutation
>;
export type DeleteConclusionMutationResult =
  Apollo.MutationResult<Types.DeleteConclusionMutation>;
export type DeleteConclusionMutationOptions = Apollo.BaseMutationOptions<
  Types.DeleteConclusionMutation,
  Types.DeleteConclusionMutationVariables
>;
export const DeleteTherapistConclusionDocument = gql`
  mutation DeleteTherapistConclusion($id: DeleteTherapistConclusionInput!) {
    deleteTherapistConclusion(input: $id) {
      ...DeleteTherapistConclusionPayload
    }
  }
  ${DeleteTherapistConclusionPayloadFragmentDoc}
`;
export type DeleteTherapistConclusionMutationFn = Apollo.MutationFunction<
  Types.DeleteTherapistConclusionMutation,
  Types.DeleteTherapistConclusionMutationVariables
>;

/**
 * __useDeleteTherapistConclusionMutation__
 *
 * To run a mutation, you first call `useDeleteTherapistConclusionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTherapistConclusionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTherapistConclusionMutation, { data, loading, error }] = useDeleteTherapistConclusionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTherapistConclusionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.DeleteTherapistConclusionMutation,
    Types.DeleteTherapistConclusionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.DeleteTherapistConclusionMutation,
    Types.DeleteTherapistConclusionMutationVariables
  >(DeleteTherapistConclusionDocument, options);
}
export type DeleteTherapistConclusionMutationHookResult = ReturnType<
  typeof useDeleteTherapistConclusionMutation
>;
export type DeleteTherapistConclusionMutationResult =
  Apollo.MutationResult<Types.DeleteTherapistConclusionMutation>;
export type DeleteTherapistConclusionMutationOptions =
  Apollo.BaseMutationOptions<
    Types.DeleteTherapistConclusionMutation,
    Types.DeleteTherapistConclusionMutationVariables
  >;
export const DiagnosesDocument = gql`
  query Diagnoses(
    $skip: Int
    $take: Int
    $order: [DiagnosisSortInput!]
    $where: DiagnosisOutputFilterInput
  ) {
    diagnoses(skip: $skip, take: $take, order: $order, where: $where) {
      totalCount
      items {
        ...DiagnosisView
      }
    }
  }
  ${DiagnosisViewFragmentDoc}
`;

/**
 * __useDiagnosesQuery__
 *
 * To run a query within a React component, call `useDiagnosesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDiagnosesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDiagnosesQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      order: // value for 'order'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDiagnosesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.DiagnosesQuery,
    Types.DiagnosesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.DiagnosesQuery, Types.DiagnosesQueryVariables>(
    DiagnosesDocument,
    options
  );
}
export function useDiagnosesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.DiagnosesQuery,
    Types.DiagnosesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.DiagnosesQuery,
    Types.DiagnosesQueryVariables
  >(DiagnosesDocument, options);
}
export type DiagnosesQueryHookResult = ReturnType<typeof useDiagnosesQuery>;
export type DiagnosesLazyQueryHookResult = ReturnType<
  typeof useDiagnosesLazyQuery
>;
export type DiagnosesQueryResult = Apollo.QueryResult<
  Types.DiagnosesQuery,
  Types.DiagnosesQueryVariables
>;
export const LoadConclusionDocument = gql`
  mutation LoadConclusion($input: LoadConclusionInput!) {
    loadConclusion(input: $input) {
      ...LoadConclusionPayload
    }
  }
  ${LoadConclusionPayloadFragmentDoc}
`;
export type LoadConclusionMutationFn = Apollo.MutationFunction<
  Types.LoadConclusionMutation,
  Types.LoadConclusionMutationVariables
>;

/**
 * __useLoadConclusionMutation__
 *
 * To run a mutation, you first call `useLoadConclusionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoadConclusionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loadConclusionMutation, { data, loading, error }] = useLoadConclusionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoadConclusionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.LoadConclusionMutation,
    Types.LoadConclusionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.LoadConclusionMutation,
    Types.LoadConclusionMutationVariables
  >(LoadConclusionDocument, options);
}
export type LoadConclusionMutationHookResult = ReturnType<
  typeof useLoadConclusionMutation
>;
export type LoadConclusionMutationResult =
  Apollo.MutationResult<Types.LoadConclusionMutation>;
export type LoadConclusionMutationOptions = Apollo.BaseMutationOptions<
  Types.LoadConclusionMutation,
  Types.LoadConclusionMutationVariables
>;
export const LoadTherapistConclusionDocument = gql`
  mutation LoadTherapistConclusion($input: LoadTherapistConclusionInput!) {
    loadTherapistConclusion(input: $input) {
      ...LoadTherapistConclusionPayload
    }
  }
  ${LoadTherapistConclusionPayloadFragmentDoc}
`;
export type LoadTherapistConclusionMutationFn = Apollo.MutationFunction<
  Types.LoadTherapistConclusionMutation,
  Types.LoadTherapistConclusionMutationVariables
>;

/**
 * __useLoadTherapistConclusionMutation__
 *
 * To run a mutation, you first call `useLoadTherapistConclusionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoadTherapistConclusionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loadTherapistConclusionMutation, { data, loading, error }] = useLoadTherapistConclusionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoadTherapistConclusionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.LoadTherapistConclusionMutation,
    Types.LoadTherapistConclusionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.LoadTherapistConclusionMutation,
    Types.LoadTherapistConclusionMutationVariables
  >(LoadTherapistConclusionDocument, options);
}
export type LoadTherapistConclusionMutationHookResult = ReturnType<
  typeof useLoadTherapistConclusionMutation
>;
export type LoadTherapistConclusionMutationResult =
  Apollo.MutationResult<Types.LoadTherapistConclusionMutation>;
export type LoadTherapistConclusionMutationOptions = Apollo.BaseMutationOptions<
  Types.LoadTherapistConclusionMutation,
  Types.LoadTherapistConclusionMutationVariables
>;
export const GetConclusionFileHistoriesDocument = gql`
  query GetConclusionFileHistories(
    $skip: Int
    $take: Int
    $order: [ConclusionFileHistorySortInput!]
    $where: ConclusionFileHistoryOutputFilterInput
  ) {
    conclusionFileHistories(
      skip: $skip
      take: $take
      order: $order
      where: $where
    ) {
      totalCount
      items {
        ...ConclusionFileHistoryItem
      }
    }
  }
  ${ConclusionFileHistoryItemFragmentDoc}
`;

/**
 * __useGetConclusionFileHistoriesQuery__
 *
 * To run a query within a React component, call `useGetConclusionFileHistoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConclusionFileHistoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConclusionFileHistoriesQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      order: // value for 'order'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetConclusionFileHistoriesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.GetConclusionFileHistoriesQuery,
    Types.GetConclusionFileHistoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.GetConclusionFileHistoriesQuery,
    Types.GetConclusionFileHistoriesQueryVariables
  >(GetConclusionFileHistoriesDocument, options);
}
export function useGetConclusionFileHistoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GetConclusionFileHistoriesQuery,
    Types.GetConclusionFileHistoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.GetConclusionFileHistoriesQuery,
    Types.GetConclusionFileHistoriesQueryVariables
  >(GetConclusionFileHistoriesDocument, options);
}
export type GetConclusionFileHistoriesQueryHookResult = ReturnType<
  typeof useGetConclusionFileHistoriesQuery
>;
export type GetConclusionFileHistoriesLazyQueryHookResult = ReturnType<
  typeof useGetConclusionFileHistoriesLazyQuery
>;
export type GetConclusionFileHistoriesQueryResult = Apollo.QueryResult<
  Types.GetConclusionFileHistoriesQuery,
  Types.GetConclusionFileHistoriesQueryVariables
>;
export const GetClientsHistoryAsyncDocument = gql`
  query GetClientsHistoryAsync(
    $skip: Int
    $take: Int
    $order: [HistorySortInput!]
    $where: HistoryOutputFilterInput
  ) {
    clientsHistory(skip: $skip, take: $take, order: $order, where: $where) {
      totalCount
      items {
        ...HistoryItem
      }
    }
  }
  ${HistoryItemFragmentDoc}
`;

/**
 * __useGetClientsHistoryAsyncQuery__
 *
 * To run a query within a React component, call `useGetClientsHistoryAsyncQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientsHistoryAsyncQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientsHistoryAsyncQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      order: // value for 'order'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetClientsHistoryAsyncQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.GetClientsHistoryAsyncQuery,
    Types.GetClientsHistoryAsyncQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.GetClientsHistoryAsyncQuery,
    Types.GetClientsHistoryAsyncQueryVariables
  >(GetClientsHistoryAsyncDocument, options);
}
export function useGetClientsHistoryAsyncLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GetClientsHistoryAsyncQuery,
    Types.GetClientsHistoryAsyncQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.GetClientsHistoryAsyncQuery,
    Types.GetClientsHistoryAsyncQueryVariables
  >(GetClientsHistoryAsyncDocument, options);
}
export type GetClientsHistoryAsyncQueryHookResult = ReturnType<
  typeof useGetClientsHistoryAsyncQuery
>;
export type GetClientsHistoryAsyncLazyQueryHookResult = ReturnType<
  typeof useGetClientsHistoryAsyncLazyQuery
>;
export type GetClientsHistoryAsyncQueryResult = Apollo.QueryResult<
  Types.GetClientsHistoryAsyncQuery,
  Types.GetClientsHistoryAsyncQueryVariables
>;
export const ClientDocument = gql`
  query Client($id: UUID!) {
    client(id: $id) {
      ...ClientView
    }
  }
  ${ClientViewFragmentDoc}
`;

/**
 * __useClientQuery__
 *
 * To run a query within a React component, call `useClientQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useClientQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.ClientQuery,
    Types.ClientQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.ClientQuery, Types.ClientQueryVariables>(
    ClientDocument,
    options
  );
}
export function useClientLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.ClientQuery,
    Types.ClientQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.ClientQuery, Types.ClientQueryVariables>(
    ClientDocument,
    options
  );
}
export type ClientQueryHookResult = ReturnType<typeof useClientQuery>;
export type ClientLazyQueryHookResult = ReturnType<typeof useClientLazyQuery>;
export type ClientQueryResult = Apollo.QueryResult<
  Types.ClientQuery,
  Types.ClientQueryVariables
>;
export const ClientsDocument = gql`
  query Clients(
    $skip: Int
    $take: Int
    $order: [ClientSortInput!]
    $where: ClientOutputFilterInput
  ) {
    clients(skip: $skip, take: $take, order: $order, where: $where) {
      totalCount
      items {
        ...ClientItem
      }
    }
  }
  ${ClientItemFragmentDoc}
`;

/**
 * __useClientsQuery__
 *
 * To run a query within a React component, call `useClientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientsQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      order: // value for 'order'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useClientsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.ClientsQuery,
    Types.ClientsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.ClientsQuery, Types.ClientsQueryVariables>(
    ClientsDocument,
    options
  );
}
export function useClientsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.ClientsQuery,
    Types.ClientsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.ClientsQuery, Types.ClientsQueryVariables>(
    ClientsDocument,
    options
  );
}
export type ClientsQueryHookResult = ReturnType<typeof useClientsQuery>;
export type ClientsLazyQueryHookResult = ReturnType<typeof useClientsLazyQuery>;
export type ClientsQueryResult = Apollo.QueryResult<
  Types.ClientsQuery,
  Types.ClientsQueryVariables
>;
export const CreateOrganizationDocument = gql`
  mutation createOrganization($input: CreateOrganizationInput!) {
    createOrganization(organizationInput: $input) {
      ...CreateOrganizationPayload
    }
  }
  ${CreateOrganizationPayloadFragmentDoc}
`;
export type CreateOrganizationMutationFn = Apollo.MutationFunction<
  Types.CreateOrganizationMutation,
  Types.CreateOrganizationMutationVariables
>;

/**
 * __useCreateOrganizationMutation__
 *
 * To run a mutation, you first call `useCreateOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrganizationMutation, { data, loading, error }] = useCreateOrganizationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrganizationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CreateOrganizationMutation,
    Types.CreateOrganizationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.CreateOrganizationMutation,
    Types.CreateOrganizationMutationVariables
  >(CreateOrganizationDocument, options);
}
export type CreateOrganizationMutationHookResult = ReturnType<
  typeof useCreateOrganizationMutation
>;
export type CreateOrganizationMutationResult =
  Apollo.MutationResult<Types.CreateOrganizationMutation>;
export type CreateOrganizationMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateOrganizationMutation,
  Types.CreateOrganizationMutationVariables
>;
export const OkvedsDocument = gql`
  query Okveds(
    $skip: Int
    $take: Int
    $order: [OkvedSortInput!]
    $where: OkvedOutputFilterInput
  ) {
    okveds(skip: $skip, take: $take, order: $order, where: $where) {
      totalCount
      items {
        ...OkvedView
      }
    }
  }
  ${OkvedViewFragmentDoc}
`;

/**
 * __useOkvedsQuery__
 *
 * To run a query within a React component, call `useOkvedsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOkvedsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOkvedsQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      order: // value for 'order'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useOkvedsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.OkvedsQuery,
    Types.OkvedsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.OkvedsQuery, Types.OkvedsQueryVariables>(
    OkvedsDocument,
    options
  );
}
export function useOkvedsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.OkvedsQuery,
    Types.OkvedsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.OkvedsQuery, Types.OkvedsQueryVariables>(
    OkvedsDocument,
    options
  );
}
export type OkvedsQueryHookResult = ReturnType<typeof useOkvedsQuery>;
export type OkvedsLazyQueryHookResult = ReturnType<typeof useOkvedsLazyQuery>;
export type OkvedsQueryResult = Apollo.QueryResult<
  Types.OkvedsQuery,
  Types.OkvedsQueryVariables
>;
export const OrganizationDocument = gql`
  query Organization($id: Int!) {
    organization(id: $id) {
      ...OrganizationView
    }
  }
  ${OrganizationViewFragmentDoc}
`;

/**
 * __useOrganizationQuery__
 *
 * To run a query within a React component, call `useOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrganizationQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.OrganizationQuery,
    Types.OrganizationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.OrganizationQuery,
    Types.OrganizationQueryVariables
  >(OrganizationDocument, options);
}
export function useOrganizationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.OrganizationQuery,
    Types.OrganizationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.OrganizationQuery,
    Types.OrganizationQueryVariables
  >(OrganizationDocument, options);
}
export type OrganizationQueryHookResult = ReturnType<
  typeof useOrganizationQuery
>;
export type OrganizationLazyQueryHookResult = ReturnType<
  typeof useOrganizationLazyQuery
>;
export type OrganizationQueryResult = Apollo.QueryResult<
  Types.OrganizationQuery,
  Types.OrganizationQueryVariables
>;
export const OrganizationsDocument = gql`
  query Organizations(
    $skip: Int
    $take: Int
    $order: [OrganizationSortInput!]
    $where: OrganizationOutputFilterInput
  ) {
    organizations(skip: $skip, take: $take, order: $order, where: $where) {
      totalCount
      items {
        ...Organization
      }
    }
  }
  ${OrganizationFragmentDoc}
`;

/**
 * __useOrganizationsQuery__
 *
 * To run a query within a React component, call `useOrganizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationsQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      order: // value for 'order'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useOrganizationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.OrganizationsQuery,
    Types.OrganizationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.OrganizationsQuery,
    Types.OrganizationsQueryVariables
  >(OrganizationsDocument, options);
}
export function useOrganizationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.OrganizationsQuery,
    Types.OrganizationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.OrganizationsQuery,
    Types.OrganizationsQueryVariables
  >(OrganizationsDocument, options);
}
export type OrganizationsQueryHookResult = ReturnType<
  typeof useOrganizationsQuery
>;
export type OrganizationsLazyQueryHookResult = ReturnType<
  typeof useOrganizationsLazyQuery
>;
export type OrganizationsQueryResult = Apollo.QueryResult<
  Types.OrganizationsQuery,
  Types.OrganizationsQueryVariables
>;
export const UpdateOrganizationDocument = gql`
  mutation UpdateOrganization($input: UpdateOrganizationInput!) {
    updateOrganization(input: $input) {
      ...UpdateOrganizationPayload
    }
  }
  ${UpdateOrganizationPayloadFragmentDoc}
`;
export type UpdateOrganizationMutationFn = Apollo.MutationFunction<
  Types.UpdateOrganizationMutation,
  Types.UpdateOrganizationMutationVariables
>;

/**
 * __useUpdateOrganizationMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationMutation, { data, loading, error }] = useUpdateOrganizationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrganizationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdateOrganizationMutation,
    Types.UpdateOrganizationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.UpdateOrganizationMutation,
    Types.UpdateOrganizationMutationVariables
  >(UpdateOrganizationDocument, options);
}
export type UpdateOrganizationMutationHookResult = ReturnType<
  typeof useUpdateOrganizationMutation
>;
export type UpdateOrganizationMutationResult =
  Apollo.MutationResult<Types.UpdateOrganizationMutation>;
export type UpdateOrganizationMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateOrganizationMutation,
  Types.UpdateOrganizationMutationVariables
>;
export const CheckCspCertDocument = gql`
  query CheckCspCert($cspSignature: String!) {
    checkCspCert(cspSignature: $cspSignature)
  }
`;

/**
 * __useCheckCspCertQuery__
 *
 * To run a query within a React component, call `useCheckCspCertQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckCspCertQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckCspCertQuery({
 *   variables: {
 *      cspSignature: // value for 'cspSignature'
 *   },
 * });
 */
export function useCheckCspCertQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.CheckCspCertQuery,
    Types.CheckCspCertQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.CheckCspCertQuery,
    Types.CheckCspCertQueryVariables
  >(CheckCspCertDocument, options);
}
export function useCheckCspCertLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.CheckCspCertQuery,
    Types.CheckCspCertQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.CheckCspCertQuery,
    Types.CheckCspCertQueryVariables
  >(CheckCspCertDocument, options);
}
export type CheckCspCertQueryHookResult = ReturnType<
  typeof useCheckCspCertQuery
>;
export type CheckCspCertLazyQueryHookResult = ReturnType<
  typeof useCheckCspCertLazyQuery
>;
export type CheckCspCertQueryResult = Apollo.QueryResult<
  Types.CheckCspCertQuery,
  Types.CheckCspCertQueryVariables
>;
export const CreateCspDocument = gql`
  mutation CreateCsp($cspInput: CreateCspInput!) {
    createCsp(input: $cspInput) {
      ...CreateCspPayload
    }
  }
  ${CreateCspPayloadFragmentDoc}
`;
export type CreateCspMutationFn = Apollo.MutationFunction<
  Types.CreateCspMutation,
  Types.CreateCspMutationVariables
>;

/**
 * __useCreateCspMutation__
 *
 * To run a mutation, you first call `useCreateCspMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCspMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCspMutation, { data, loading, error }] = useCreateCspMutation({
 *   variables: {
 *      cspInput: // value for 'cspInput'
 *   },
 * });
 */
export function useCreateCspMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CreateCspMutation,
    Types.CreateCspMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.CreateCspMutation,
    Types.CreateCspMutationVariables
  >(CreateCspDocument, options);
}
export type CreateCspMutationHookResult = ReturnType<
  typeof useCreateCspMutation
>;
export type CreateCspMutationResult =
  Apollo.MutationResult<Types.CreateCspMutation>;
export type CreateCspMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateCspMutation,
  Types.CreateCspMutationVariables
>;
export const GetUserCspDocument = gql`
  query GetUserCsp {
    actualUserCsp {
      ...CspPayload
    }
  }
  ${CspPayloadFragmentDoc}
`;

/**
 * __useGetUserCspQuery__
 *
 * To run a query within a React component, call `useGetUserCspQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserCspQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserCspQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserCspQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.GetUserCspQuery,
    Types.GetUserCspQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.GetUserCspQuery, Types.GetUserCspQueryVariables>(
    GetUserCspDocument,
    options
  );
}
export function useGetUserCspLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GetUserCspQuery,
    Types.GetUserCspQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.GetUserCspQuery,
    Types.GetUserCspQueryVariables
  >(GetUserCspDocument, options);
}
export type GetUserCspQueryHookResult = ReturnType<typeof useGetUserCspQuery>;
export type GetUserCspLazyQueryHookResult = ReturnType<
  typeof useGetUserCspLazyQuery
>;
export type GetUserCspQueryResult = Apollo.QueryResult<
  Types.GetUserCspQuery,
  Types.GetUserCspQueryVariables
>;
export const UserProfileDocument = gql`
  query UserProfile {
    userProfile {
      ...UserProfileView
    }
  }
  ${UserProfileViewFragmentDoc}
`;

/**
 * __useUserProfileQuery__
 *
 * To run a query within a React component, call `useUserProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserProfileQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.UserProfileQuery,
    Types.UserProfileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.UserProfileQuery,
    Types.UserProfileQueryVariables
  >(UserProfileDocument, options);
}
export function useUserProfileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.UserProfileQuery,
    Types.UserProfileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.UserProfileQuery,
    Types.UserProfileQueryVariables
  >(UserProfileDocument, options);
}
export type UserProfileQueryHookResult = ReturnType<typeof useUserProfileQuery>;
export type UserProfileLazyQueryHookResult = ReturnType<
  typeof useUserProfileLazyQuery
>;
export type UserProfileQueryResult = Apollo.QueryResult<
  Types.UserProfileQuery,
  Types.UserProfileQueryVariables
>;
export const ReportsDocument = gql`
  query Reports(
    $roleName: String!
    $skip: Int
    $take: Int
    $order: [ReportSortInput!]
    $where: ReportOutputFilterInput
  ) {
    reports(
      roleName: $roleName
      skip: $skip
      take: $take
      order: $order
      where: $where
    ) {
      totalCount
      items {
        ...ReportView
      }
    }
  }
  ${ReportViewFragmentDoc}
`;

/**
 * __useReportsQuery__
 *
 * To run a query within a React component, call `useReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportsQuery({
 *   variables: {
 *      roleName: // value for 'roleName'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      order: // value for 'order'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useReportsQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.ReportsQuery,
    Types.ReportsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.ReportsQuery, Types.ReportsQueryVariables>(
    ReportsDocument,
    options
  );
}
export function useReportsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.ReportsQuery,
    Types.ReportsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.ReportsQuery, Types.ReportsQueryVariables>(
    ReportsDocument,
    options
  );
}
export type ReportsQueryHookResult = ReturnType<typeof useReportsQuery>;
export type ReportsLazyQueryHookResult = ReturnType<typeof useReportsLazyQuery>;
export type ReportsQueryResult = Apollo.QueryResult<
  Types.ReportsQuery,
  Types.ReportsQueryVariables
>;
export const CreateUserDocument = gql`
  mutation CreateUser($userInput: CreateUserInput!) {
    createUser(input: $userInput) {
      ...CreateUserPayload
    }
  }
  ${CreateUserPayloadFragmentDoc}
`;
export type CreateUserMutationFn = Apollo.MutationFunction<
  Types.CreateUserMutation,
  Types.CreateUserMutationVariables
>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      userInput: // value for 'userInput'
 *   },
 * });
 */
export function useCreateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CreateUserMutation,
    Types.CreateUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.CreateUserMutation,
    Types.CreateUserMutationVariables
  >(CreateUserDocument, options);
}
export type CreateUserMutationHookResult = ReturnType<
  typeof useCreateUserMutation
>;
export type CreateUserMutationResult =
  Apollo.MutationResult<Types.CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateUserMutation,
  Types.CreateUserMutationVariables
>;
export const DeleteUserDocument = gql`
  mutation DeleteUser($id: DeleteUserInput!) {
    deleteUser(input: $id) {
      ...DeleteUserPayload
    }
  }
  ${DeleteUserPayloadFragmentDoc}
`;
export type DeleteUserMutationFn = Apollo.MutationFunction<
  Types.DeleteUserMutation,
  Types.DeleteUserMutationVariables
>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.DeleteUserMutation,
    Types.DeleteUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.DeleteUserMutation,
    Types.DeleteUserMutationVariables
  >(DeleteUserDocument, options);
}
export type DeleteUserMutationHookResult = ReturnType<
  typeof useDeleteUserMutation
>;
export type DeleteUserMutationResult =
  Apollo.MutationResult<Types.DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<
  Types.DeleteUserMutation,
  Types.DeleteUserMutationVariables
>;
export const ProfilesDocument = gql`
  query Profiles {
    profiles {
      totalCount
      items {
        ...ProfileView
      }
    }
  }
  ${ProfileViewFragmentDoc}
`;

/**
 * __useProfilesQuery__
 *
 * To run a query within a React component, call `useProfilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfilesQuery({
 *   variables: {
 *   },
 * });
 */
export function useProfilesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.ProfilesQuery,
    Types.ProfilesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.ProfilesQuery, Types.ProfilesQueryVariables>(
    ProfilesDocument,
    options
  );
}
export function useProfilesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.ProfilesQuery,
    Types.ProfilesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.ProfilesQuery, Types.ProfilesQueryVariables>(
    ProfilesDocument,
    options
  );
}
export type ProfilesQueryHookResult = ReturnType<typeof useProfilesQuery>;
export type ProfilesLazyQueryHookResult = ReturnType<
  typeof useProfilesLazyQuery
>;
export type ProfilesQueryResult = Apollo.QueryResult<
  Types.ProfilesQuery,
  Types.ProfilesQueryVariables
>;
export const RolesDocument = gql`
  query Roles {
    roles {
      totalCount
      items {
        ...RoleView
      }
    }
  }
  ${RoleViewFragmentDoc}
`;

/**
 * __useRolesQuery__
 *
 * To run a query within a React component, call `useRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useRolesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.RolesQuery,
    Types.RolesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.RolesQuery, Types.RolesQueryVariables>(
    RolesDocument,
    options
  );
}
export function useRolesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.RolesQuery,
    Types.RolesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.RolesQuery, Types.RolesQueryVariables>(
    RolesDocument,
    options
  );
}
export type RolesQueryHookResult = ReturnType<typeof useRolesQuery>;
export type RolesLazyQueryHookResult = ReturnType<typeof useRolesLazyQuery>;
export type RolesQueryResult = Apollo.QueryResult<
  Types.RolesQuery,
  Types.RolesQueryVariables
>;
export const UpdateUserDocument = gql`
  mutation UpdateUser($userInput: UpdateUserInput!) {
    updateUser(input: $userInput) {
      ...UpdateUserPayload
    }
  }
  ${UpdateUserPayloadFragmentDoc}
`;
export type UpdateUserMutationFn = Apollo.MutationFunction<
  Types.UpdateUserMutation,
  Types.UpdateUserMutationVariables
>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      userInput: // value for 'userInput'
 *   },
 * });
 */
export function useUpdateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdateUserMutation,
    Types.UpdateUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.UpdateUserMutation,
    Types.UpdateUserMutationVariables
  >(UpdateUserDocument, options);
}
export type UpdateUserMutationHookResult = ReturnType<
  typeof useUpdateUserMutation
>;
export type UpdateUserMutationResult =
  Apollo.MutationResult<Types.UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateUserMutation,
  Types.UpdateUserMutationVariables
>;
export const UserDocument = gql`
  query User($id: UUID!) {
    user(id: $id) {
      ...UserView
    }
  }
  ${UserViewFragmentDoc}
`;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.UserQuery,
    Types.UserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.UserQuery, Types.UserQueryVariables>(
    UserDocument,
    options
  );
}
export function useUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.UserQuery,
    Types.UserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.UserQuery, Types.UserQueryVariables>(
    UserDocument,
    options
  );
}
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = Apollo.QueryResult<
  Types.UserQuery,
  Types.UserQueryVariables
>;
export const UsersDocument = gql`
  query Users(
    $skip: Int
    $take: Int
    $order: [UserSortInput!]
    $where: UserOutputFilterInput
  ) {
    users(skip: $skip, take: $take, order: $order, where: $where) {
      totalCount
      items {
        ...UserItem
      }
    }
  }
  ${UserItemFragmentDoc}
`;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      order: // value for 'order'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUsersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.UsersQuery,
    Types.UsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.UsersQuery, Types.UsersQueryVariables>(
    UsersDocument,
    options
  );
}
export function useUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.UsersQuery,
    Types.UsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.UsersQuery, Types.UsersQueryVariables>(
    UsersDocument,
    options
  );
}
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = Apollo.QueryResult<
  Types.UsersQuery,
  Types.UsersQueryVariables
>;
