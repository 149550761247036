import { OrganizationValues } from "../types";

export const getInitialValues = (): OrganizationValues => {
  return {
    id: 0,
    name: "",
    address: "",
    inn: "",
    ogrn: "",
    kpp: "",
    licenseNumber: "",
    licenseDate: "",
    licenseAuthority: "",
    phone: "",
    email: "",
    organizationCode: "",
    republicCode: "",
    licenseFile: undefined,
    printFile: undefined,
    licenseFileText: "",
    printFileText: "",
    mainOkved: { id: undefined, name: "" },
    additionalOkveds: [],
  };
};
