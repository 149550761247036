import { Formik } from "formik";
import { Drawer, DrawerContent } from "@genome-frontend/uikit/build/lib/uikit";
import { initialValues } from "./initialValues";
import { DrawerForm } from "components/Form";
import { ClientCreateForm } from "../ClientForm";
import { getInputDataFromValues } from "./getInputDataFromValues";
import { isAllFormValuesWasTouched } from "utils/isAllFormValuesWasTouched";
import { ClientCreateFromValidate } from "./ClientCreateFromValidate";
import { useCreateClient } from "../../hooks/useCreateClient";
import { useConfirm } from "components/Confirm";
import { formCloseHandler } from "components/Form/formCloseHandler";
import { ClientCreateValue, ClientOutput } from "../../types";

type TProps = {
  onSuccess: (user: ClientOutput) => void;
  onCancel: () => void;
};

export const ClientCreateDrawer = ({ onSuccess, onCancel }: TProps) => {
  const { addOkConfirm } = useConfirm();
  const { createClient } = useCreateClient({ onSuccess });

  const closeHandler = async (values: ClientCreateValue) =>
    formCloseHandler({ values, initialValues, onCancel, addOkConfirm });

  let values: ClientCreateValue;

  return (
    <Drawer
      sx={{
        "& .MuiPaper-root": {
          width: 1000,
        },
      }}
      open={true}
      onClose={() => closeHandler(values)}
    >
      <Formik<ClientCreateValue>
        onSubmit={createClient}
        initialValues={initialValues}
        validate={ClientCreateFromValidate}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {(props) => {
          values = props.values;
          return (
            <DrawerForm>
              <DrawerContent
                onClose={() => closeHandler(values)}
                title="Регистрация пользователя"
                confirmButtonProps={{
                  children: "Создать",
                  disabled: !(
                    props.isValid &&
                    props.dirty &&
                    isAllFormValuesWasTouched(props, getInputDataFromValues)
                  ),
                }}
                cancelButtonProps={{ onClick: () => closeHandler(values) }}
              >
                <ClientCreateForm />
              </DrawerContent>
            </DrawerForm>
          );
        }}
      </Formik>
    </Drawer>
  );
};
