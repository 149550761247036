import jwt_decode from "jwt-decode";
import { UserProfile } from "types/UserProfile";
import { parsePermissionsFromJwt } from "./utils/parsePermissionsFromJwt";

const storage = window.localStorage;

export enum EStorageField {
  USER = "USER",
  USER_ID = "USER_ID",
  USER_NAME = "USER_NAME",
  USER_SURNAME = "USER_SURNAME",
  USER_FATHERSNAME = "USER_FATHERSNAME",
  USER_ROLE = "USER_ROLE",
  USER_PROFILES = "USER_PROFILES",
  USER_REGION_CODE = "USER_REGION_CODE",
  USER_ORGANIZATION_CODE = "USER_ORGANIZATION_CODE",
  USER_ORGANIZATION_ID = "USER_ORGANIZATION_ID",
  USER_ORGANIZATION_NAME = "USER_ORGANIZATION_NAME",
  TOKEN = "TOKEN",
  REFRESH_TOKEN = "REFRESH_TOKEN",
  TOKEN_EXPIRATION_OFFSET = "TOKEN_EXPIRATION_OFFSET",
  OPENED_TOKEN = "OPENED_TOKEN",
}

type Token = {
  token: string;
  expiration: string;
};

export const TokenHelper = {
  setTokenExpirationOffset(offset: number) {
    storage.setItem(EStorageField.TOKEN_EXPIRATION_OFFSET, offset.toString());
  },

  setAuthorizationToken(token: string) {
    storage.setItem(EStorageField.TOKEN, token);

    const tokenItems = jwt_decode(token) as any;

    const userId = tokenItems.user_id;
    storage.setItem(EStorageField.USER_ID, userId);

    const userName = tokenItems.user_name;
    storage.setItem(EStorageField.USER_NAME, userName);

    const userSurname = tokenItems.user_surname;
    storage.setItem(EStorageField.USER_SURNAME, userSurname);

    const userFathersname = tokenItems.user_fathersname;
    storage.setItem(EStorageField.USER_FATHERSNAME, userFathersname);

    const userRole = tokenItems.user_role;
    storage.setItem(EStorageField.USER_ROLE, userRole);

    const userProfiles = tokenItems.user_profiles;
    storage.setItem(EStorageField.USER_PROFILES, userProfiles);

    const userRegionCode = tokenItems.region_code;
    storage.setItem(EStorageField.USER_REGION_CODE, userRegionCode);

    const userOrganizationCode = tokenItems.organization_code;
    storage.setItem(EStorageField.USER_ORGANIZATION_CODE, userOrganizationCode);

    const userOrganizationId = tokenItems.organization_id;
    storage.setItem(EStorageField.USER_ORGANIZATION_ID, userOrganizationId);

    const userOrganizationName = tokenItems.organization_name;
    storage.setItem(EStorageField.USER_ORGANIZATION_NAME, userOrganizationName);
  },

  setRefreshToken(refreshToken: string) {
    storage.setItem(EStorageField.REFRESH_TOKEN, refreshToken);
  },

  logout() {
    const thumbprint = storage.getItem("thumbprint");
    storage.clear();
    if (thumbprint) {
      storage.setItem("thumbprint", thumbprint);
    }
  },

  removeUser() {
    storage.removeItem(EStorageField.USER);
    storage.removeItem(EStorageField.USER_ID);
    storage.removeItem(EStorageField.USER_NAME);
    storage.removeItem(EStorageField.USER_SURNAME);
    storage.removeItem(EStorageField.USER_FATHERSNAME);
    storage.removeItem(EStorageField.USER_ROLE);
    storage.removeItem(EStorageField.USER_PROFILES);
    storage.removeItem(EStorageField.USER_REGION_CODE);
    storage.removeItem(EStorageField.USER_ORGANIZATION_CODE);
  },

  removeToken() {
    storage.removeItem(EStorageField.TOKEN);
  },

  removeRefreshToken() {
    storage.removeItem(EStorageField.REFRESH_TOKEN);
  },

  getCurrentToken(): string | null {
    return storage.getItem(EStorageField.TOKEN);
  },

  getCurrentRefreshToken(): string | null {
    return storage.getItem(EStorageField.REFRESH_TOKEN);
  },

  getCurrentUserId(): string | null {
    return storage.getItem(EStorageField.USER_ID);
  },

  getCurrentUserName(): string | null {
    return storage.getItem(EStorageField.USER_NAME);
  },

  getCurrentUserSurname(): string | null {
    return storage.getItem(EStorageField.USER_SURNAME);
  },

  getCurrentUserFathersName(): string | null {
    return storage.getItem(EStorageField.USER_FATHERSNAME);
  },

  getCurrentUserFio(): string | null {
    return `${this.getCurrentUserSurname()} ${this.getCurrentUserName()} ${this.getCurrentUserFathersName()}`;
  },

  getCurrentUserRole(): string | null {
    return storage.getItem(EStorageField.USER_ROLE);
  },

  getCurrentUserProfiles(): string | null {
    return storage.getItem(EStorageField.USER_PROFILES);
  },

  getUserAccesses(): string[] {
    return parsePermissionsFromJwt(this.getCurrentToken());
  },

  checkAccessPermission(permission: string): boolean {
    const permissions = parsePermissionsFromJwt(this.getCurrentToken());
    return permissions.includes(permission);
  },

  checkIsNarcologyProfile(): boolean {
    return storage
      .getItem(EStorageField.USER_PROFILES)
      ?.includes(UserProfile.Narcology)
      ? true
      : false;
  },

  checkIsPsychiatristProfile(): boolean {
    return storage
      .getItem(EStorageField.USER_PROFILES)
      ?.includes(UserProfile.Psychiatrist)
      ? true
      : false;
  },

  checkIsTherapistProfile(): boolean {
    return storage
      .getItem(EStorageField.USER_PROFILES)
      ?.includes(UserProfile.Therapist)
      ? true
      : false;
  },

  getRegionCode(): number {
    return Number(storage.getItem(EStorageField.USER_REGION_CODE));
  },

  getOrganizationCode(): number {
    return Number(storage.getItem(EStorageField.USER_ORGANIZATION_CODE));
  },

  getOrganizationId(): number {
    return Number(storage.getItem(EStorageField.USER_ORGANIZATION_ID));
  },

  getOrganizationName(): string {
    return storage.getItem(EStorageField.USER_ORGANIZATION_NAME)!;
  },

  setOpenedToken(token: string) {
    storage.setItem(EStorageField.OPENED_TOKEN, token);
  },

  getOpenedToken(): string {
    return storage.getItem(EStorageField.OPENED_TOKEN)!;
  },
};
