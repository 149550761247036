import { useNavigate } from "react-router";
import { paths } from "router/paths";
import { ClientCreateDrawer } from "../components/ClientCreateDrawer/ClientCreateDrawer";
import { ClientOutput } from "../types";

export const ClientCreatePage = () => {
  const navigate = useNavigate();

  const closeHandler = () => {
    navigate(paths.clients());
  };

  const successHandler = (client: ClientOutput) => {
    navigate(paths.client(client?.id));
  };

  return (
    <ClientCreateDrawer onCancel={closeHandler} onSuccess={successHandler} />
  );
};
