import { MainLayout } from "../../components/MainLayout";
import { ClientsGrid } from "./components/ClientsGrid/ClientsGrid";

export function ClientsPage() {
  return (
    <MainLayout title="Пользователи">
      <ClientsGrid />
    </MainLayout>
  );
}
